<template>
	<div class="recordDetail">
	<div class="leftContent">
		<div style="margin-left: .24rem;margin-top: .16rem;" class="labelContent">
			<div class="labelBtn">
				基本信息
			</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">用户姓名</div>
			<div class="infoContent1">{{form.patientName}}</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">测评记录</div>
			<div class="infoContent2">{{form.taskNum}}</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">用户编号</div>
			<div class="infoContent2">{{form.patientCard}}</div>
		</div>
		<div style="margin-left: .24rem;margin-top: .52rem;" class="labelContent">
			<div class="labelBtn">
				测评信息
			</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">套餐名称</div>
			<div class="infoContent2">{{form.packageName}}</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">开始时间</div>
			<div class="infoContent2">{{form.startTime}}</div>
		</div>
		<div v-if="form.endTime" class="infoLine">
			<div class="infoLabel">结束时间</div>
			<div class="infoContent2">{{form.endTime}}</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">设备编号</div>
			<div v-if="dataForm.deviceTypeName" class="infoContent2">{{dataForm.deviceTypeName.typeNumber}}</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">设备名称</div>
			<div v-if="dataForm.deviceTypeName" class="infoContent2">{{dataForm.deviceTypeName.typeName}}</div>
		</div>
		<div class="infoLine">
			<div class="infoLabel">测评状态</div>

			<div v-if="form.startTime&&form.endTime&&form.isSameDay" class="infoStatus1">已完成</div>
			
			<div v-else-if="form.startTime&&!form.endTime&&form.today" class="infoStatus3">进行中</div>
			<div class="infoStatus2" v-else>已中止</div>
		</div>
		<img style="width:3.7rem;height: 2.14rem;margin-top: .91rem;margin-left: .15rem;" src="~@/assets/img/record/img1.png" alt="" />
	</div>
	<div class="midContent">
		<div style="margin-left: .24rem;margin-top: .16rem;margin-bottom: .16rem;" class="labelContent">
			<div class="labelBtn">
				数据记录
			</div>
		</div>
		<el-tabs v-model="tabName" @tab-click="handleClick" v-if="videoRecord||radioRecord||shetaiRecord" tab-position="left" style="">
			<el-tab-pane name="观看视频"  v-if="videoRecord" label="观看视频">
				<div style="width: 5.77rem;height: 3.1rem;background: #F7F7F7;padding: .1rem .44rem;box-sizing: border-box;">
					<video style="width:4.88rem;height:2.89rem" :src="videoRecord.videoUrl" controls
						width="4.88rem" height="2.89rem"></video>
				</div>
		
			</el-tab-pane>
			<el-tab-pane name="朗读文本"   v-if="radioRecord" label="朗读文本">
				<div style="width: 5.77rem;height: 3.1rem;background: #F7F7F7;padding: .1rem .44rem;">
					<video style="width:4.88rem;height:2.89rem" :src="radioRecord.videoUrl" controls
						width="4.88rem" height="2.89rem"></video>
				</div>
			</el-tab-pane>
			<el-tab-pane  name="舌苔照片"   v-if="shetaiRecord" label="舌苔照片">
				<div style="width: 5.77rem;height: 3.1rem;background: #F7F7F7;padding: .1rem .44rem;">
					<img style="width: 100%;height: 100%;" :src="shetaiRecord.videoUrl" alt="" />
				</div>
			</el-tab-pane>

		
		</el-tabs>
		<div v-else  style="text-align: center;margin-top: 56px;display: flex;justify-content: center;">
			 <img class="zwsj"  src="~@/assets/img/record/zwsj.png" alt="" />
		</div>
		
		 <div v-show="breathFlag" class="echartContent"  id="main2">
	
		 </div>
		 <div v-show="!breathFlag" class="echartContent"  >
		 			  <div class="chartTitle">呼吸检测</div>
		 			 <div   style="width: 100%;height: 100%;display: flex;justify-content: center;">
		 				
		 			 <img class="zwsj" src="~@/assets/img/record/zwsj2.png" alt="" />
		 			 </div>
		 </div>
		  <div v-show="heartFlag" class="echartContent" id="main3">
			
		  </div>
		  <div v-show="!heartFlag" class="echartContent"  >
		  			  <div class="chartTitle">心率检测</div>
		  			 <div   style="width: 100%;height: 100%;display: flex;justify-content: center;">
		  				
		  			 <img class="zwsj" src="~@/assets/img/record/zwsj2.png" alt="" />
		  			 </div>
		  </div>
	</div>
	<div class="rightContent">
		<div class="rightContent1">
			<div style="margin-left: .24rem;margin-top: .16rem;margin-bottom: .16rem;" class="labelContent">
				<div class="labelBtn">
					问卷量表		{{form.vfRelationInfo.length}}
				</div>
			</div>
			<div v-if="form.vfRelationInfo.length>0" v-for="(item,index) in form.vfRelationInfo" class="qsLine">
				<div class="qsLeftContent">
					<img style="width: .24rem;height: .24rem;margin-right: 10px;" src="../../assets/img/record/icon1.png" alt="" />
					{{index+1}}.{{item.tableName}}
				</div>
				<div @click="getInfo(item)" class="qsRightContent">
					详情
				</div>
			</div>
			<div v-if="form.vfRelationInfo.length==0"  style="text-align: center;margin-top: .56rem;display: flex;justify-content: center;">
		
				 <img class="zwsj"  src="~@/assets/img/record/zwsj3.png" alt="" />
			</div>
		</div>
		<div class="rightContent2">
			<div style="margin-left: .24rem;margin-top: .16rem;margin-bottom: .16rem;" class="labelContent">
				<div class="labelBtn">
					测评得分
				</div>
			</div>
			 <div v-if="leidaFlag" style="width: 4.1rem;height: 4.10rem;background-color: #fff;margin-left: calc(50% - 2.05rem);margin-top: .3rem;" id="main"></div>
			<div v-else  style="text-align: center;margin-top: .84rem;width: 1.7rem;margin-left: calc(50% - .85rem);">
				 <img class="zwsj4"  src="~@/assets/img/record/zwsj4.png" alt="" />
				  <img class="zwdf"  src="~@/assets/img/record/zwdf.png" alt="" />
			</div>
		</div>
	</div>
		<el-dialog width= "1228px" :visible.sync="dialogVisible">
		  <div ref="mainSroll" style="width: 1188px;height: 675px;overflow: auto;background-color: #fff;">
		  	<div class="form-title">{{ tableName }}</div>
		  		
		  	<v-form-render style="margin-left: 30px;" v-if="formJson.widgetList.length" :form-json="formJson" :form-data="formData"
		  		:option-data="optionData" ref="vFormRef"></v-form-render>
		  </div>
		 
		</el-dialog>
	</div>
</template>
<script>
	import TinyMce from "@/components/tiny-mce";
	import Global from '@/utils/global'
	import * as echarts from "echarts";
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "publicAdd",
		components: {
			TinyMce,
		},
		data() {
			return {
				activeQsName: '',
				tableName: '',
				formJson: {
					widgetList: [],
					formConfig: {},
				},
				formList: [],
				tabName:'观看视频',
				dataForm: {
					totalScore: '',
					scoreAnalysis: '',
					scoreAdvice: '',
				},
				startTime:'',
				endTime:'',
				shetaiRecord:'',
				formData: {},
				optionData: {},
				leidaFlag: true,
				edit: false,
				dialogVisible: false,
				actionUrl: '',
				importHeader: {
					token: ""
				},
				breathFlag:true,
				heartFlag :true,
				myDominUrl: '',
				fileList: [],
				fileList2: [],
				checkList: [],
				txtContent: '',
				form: {

				},
				echartsFlag:true,
				videoRecord: null,
				radioRecord: null,
				diseasesList: [],
				typeList: [],
				dataRules: {
					modelName: [{
						required: true,
						message: "AI测评模型名称不能为空",
						trigger: "blur"
					}, ],
					modelNumber: [{
						required: true,
						message: "模型编号不能为空",
						trigger: "blur"
					}, ],
					diseaseType: [{
						required: true,
						message: "请选择分类",
						trigger: "change"
					}, ],

				},
				dataListLoading: false,
				type: "",
			};
		},
		async mounted() {
			this.myDominUrl = Global.dominUrl
			console.log(this.myDominUrl)
			if (this.$route.query.id) {

				await this.init(this.$route.query.id);
				await this.leidaChart()
				await this.setEcharts()
				await this.setEcharts2()
				await this.getDevice()
			}
			

		},
		methods: {
			async getDevice(){
			
				let params = {
				
				
					stringParam1: JSON.parse(sessionStorage.evaluationRecDetail).deviceId
				
				};
				console.log("/deviceInfo/info")
				console.log(params)
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/deviceInfo/info"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
				
					},
				});
				if (res.status) {
					this.dataForm = res.data
						console.log(res.data)
				console.log('deviceInfo/info')
				
				}
			},

			async leidaChart(){
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: JSON.parse(sessionStorage.evaluationRecDetail).packageId
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
							
					},
				});
				if (res.status) {
			
					let diseaseType = JSON.parse(	res.data.diseaseType)
					for (let item of diseaseType) {
						item.max = 100
					}
					let dataObj = {
						name:this.form.patientName,
						value:[]
					}
					if(JSON.parse(sessionStorage.evaluationRecDetail).totalScore&&JSON.parse(sessionStorage.evaluationRecDetail).totalScore.length>0){
						this.leidaFlag = true
						let totalScore = JSON.parse(sessionStorage.evaluationRecDetail).totalScore
						for (let index in  totalScore) {
							dataObj.value.push(totalScore[index].score)
							
						}
					}else{
						this.leidaFlag = false
						return
					}		
				
				     var myChart = echarts.init(document.getElementById('main'));
					 let  totalScore = JSON.parse(sessionStorage.evaluationRecDetail).totalScore
				      let  option = {
				            backgroundColor:'#ffffff',

				  tooltip:{
				    confine:true,
				    enterable:true,
				    trigger: 'axis'
				  },
				  radar:[
				    {
						splitArea:{		
						       areaStyle:{
								   color:['#fff','#fff']
							   }
							},
				    // shape: 'circle',
				    indicator: diseaseType,
				    center: ['50%', '50%'],
				    
				  },
				 
				],
				 
				  series: [
				    {
				      name: '雷达图',
				      type: 'radar',
					  areaStyle: {
					            // 填充区颜色
					            color: '#fff',
					          },
				    //   radarIndex: 1,
				      tooltip: {
				        trigger: 'item'
				      },
				    //   areaStyle: {},
					
				      itemStyle:{ 
						  color: '#fff',
				    normal:{
						 color: '#1DE0E4' ,
						lineStyle:{width:2},opacity:0.6
					},
				    emphasis:{lineStyle:{width:2},opacity:1      
				  },
				 },
				      data: [dataObj]
				    },
				    
				 
				  ],
				};
				
			myChart.setOption(option);
			}
			},
			async  setEcharts() {
			
			let params = {
			
			stringParam1: this.$route.query.id,
				//stringParam1: '71cd370ef5af464a935a143ee65dff54',
				intParam1: 1,
				
			
				pageSize: 99999,
				pageNo: 1,
			};
			console.log(params)
			const {
				data: res
			} = await this.$httpAes({
				url: this.$httpAes.adornUrl("/recordRadarInfo/list"),
				method: "post",
				data: params,
				contentType: 'json',
				headers: {
					'Content-Type': 'application/json',
			
				},
			});
				
			if (res.status) {
					console.log('recordRadarInfo/list')
				console.log(res.data)
				
				let dataArr = []
				let timeArr = []
				let fullTimeArr = []
				if(res.data.length == 0){
					this.breathFlag = false
					return
					
				}else{
						this.breathFlag = true
					}
				for (let item of res.data) {
					if(Date.parse(new Date(item.infoTime))>this.startTime&&Date.parse(new Date(item.infoTime))<this.endTime){
						dataArr.push(item.infoNum)
						fullTimeArr.push(item.infoTime)
						timeArr.push(item.infoTime.substring(11))
					}
					
					
				}
				if(dataArr.length == 0){
				
					this.breathFlag = false
		
					return
					
				}
				
			let option = {
				grid: {
							
					//与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
							
					top: "20%",
							
					left: "5%",
							
					right: "6%",
							
					bottom: "20%"
							
				},
				// tooltip: {
				// 	trigger: 'axis',
				
							
				// },
				tooltip: {
					trigger: 'axis',
					formatter: function(params) {
						console.log(params)
						let domstr = ''
						//params[0].name表示x轴数据
								
								
						let str = ''
							str += fullTimeArr[params[0].dataIndex] +"</br>"
								str += '心率  '+ dataArr[params[0].dataIndex] +'次'
						//params是数组格式
						// for (let item of params) {
								
						// 	if(state.kfTab=='CEA'){
						// 		if (item.value == 0) {
						// 			str += domstr + " <span style='color:#67C23A'>暂无数据</span>"
						// 		} else if (item.value > 5) {
						// 			str += domstr + " <span style='color:#E64340'>指标异常：</span>" + " : " + item
						// 				.value + 'ng/ml'
						// 		} else {
						// 			str += domstr + " <span style='color:#67C23A'>指标正常</span>" + " : " + item
						// 				.value + 'ng/ml'
						// 		}
						// 	}
						// }
						return str
					},
								
				},
				title: {
				    text: '呼吸检测',
					textStyle:{
						fontSize:12
					}
				  },
				
							
							
							
				xAxis: {
					type: 'category',
							
					data: timeArr,
					boundaryGap: false
				},
				yAxis: {
					type: 'value',
					// max: 20,
					min: 0,
					splitNumber: 4
				},
				dataZoom: [{
							
					type: 'inside',
					xAxisIndex: [0],
					start: 0,
					end: 100,
				}, ],
				series: [{
					data: dataArr,
					type: 'line',
			lineStyle: {//设置线条颜色
					                	  normal: {
					                	    color: '#4D8AF4' // 折线线条颜色:红色
					                	  }
				                	},
									itemStyle: {//设置端点颜色
										                		  normal: {
										                		    color: '#4D8AF4' // 设置线条上点的颜色（和图例的颜色）
										                		  }
									                		}
								
				}]
			}
				  let myChart = echarts.init(document.getElementById("main2"));
				  myChart.setOption(option);
				  // 当浏览器宽度改变的时候，图表的大小也跟着改变
				  window.onresize = () => {
				  //  myChart.resize();
				  };
				  }
				},
				async  setEcharts2() {
				
				let params = {
				stringParam1: this.$route.query.id,
					//stringParam1: '71cd370ef5af464a935a143ee65dff54',
					intParam1: 2,
					
				
					pageSize: 99999,
					pageNo: 1,
				};
				console.log(params)
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/recordRadarInfo/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
				
					},
				});
					
				if (res.status) {
						console.log('recordRadarInfo/list')
					console.log(res.data)
					let dataArr = []
					let timeArr = []
					let fullTimeArr = []
					if(res.data.length == 0){
						this.heartFlag = false
						return
						
					}else{
						this.heartFlag = true
					}
					for (let item of res.data) {
						console.log(Date.parse(new Date(item.infoTime)))
						if(Date.parse(new Date(item.infoTime))>this.startTime&&Date.parse(new Date(item.infoTime))<this.endTime){
							dataArr.push(item.infoNum)
							fullTimeArr.push(item.infoTime)
							timeArr.push(item.infoTime.substring(11))
						}
						
					}
					if(dataArr.length == 0){
						this.heartFlag = false
		
						return
						
					}
					
				let option = {
					grid: {
								
						//与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
								
						top: "20%",
								
						left: "5%",
								
						right: "6%",
								
						bottom: "20%"
								
					},
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							console.log(params)
							let domstr = ''
							//params[0].name表示x轴数据
									
									
							let str = ''
								str += fullTimeArr[params[0].dataIndex] +"</br>"
									str += '呼吸  '+ dataArr[params[0].dataIndex] +'次/分'
							//params是数组格式
							// for (let item of params) {
									
							// 	if(state.kfTab=='CEA'){
							// 		if (item.value == 0) {
							// 			str += domstr + " <span style='color:#67C23A'>暂无数据</span>"
							// 		} else if (item.value > 5) {
							// 			str += domstr + " <span style='color:#E64340'>指标异常：</span>" + " : " + item
							// 				.value + 'ng/ml'
							// 		} else {
							// 			str += domstr + " <span style='color:#67C23A'>指标正常</span>" + " : " + item
							// 				.value + 'ng/ml'
							// 		}
							// 	}
							// }
							return str
						},
									
					},
					title: {
					    text: '心率检测',
						textStyle:{
							fontSize:12
						}
					  },
					
								
								
								
					xAxis: {
						type: 'category',
								
						data: timeArr,
						boundaryGap: false
					},
					yAxis: {
						type: 'value',
						// max: 20,
						min: 0,
						splitNumber: 4
					},
					dataZoom: [{
								
						type: 'inside',
						xAxisIndex: [0],
						start: 0,
						end: 100,
					}, ],
					series: [{
						data: dataArr,
						type: 'line',
				lineStyle: {//设置线条颜色
						                	  normal: {
						                	    color: '#1DE0E4' // 折线线条颜色:红色
						                	  }
					                	},
										itemStyle: {//设置端点颜色
											                		  normal: {
											                		    color: '#1DE0E4' // 设置线条上点的颜色（和图例的颜色）
											                		  }
										                		}
									
					}]
				}
				var that = this
					  let myChart = echarts.init(document.getElementById("main3"));
					  myChart.setOption(option);
					  // 当浏览器宽度改变的时候，图表的大小也跟着改变
					  window.onresize = () => {
						  that.$router.go(0)
					  //  myChart.resize();
					  };
					  }
					},
			resetScore(val) {

				if(parseInt(this.dataForm.totalScore)<parseInt(this.formList[0].score1)||parseInt(this.dataForm.totalScore)>parseInt(this.formList[this.formList.length-1].score2)){
					this.$message.error('请正确填写评分')
					this.dataForm.totalScore =''
					return
				}
				for (let item of this.formList) {
					if (parseInt(this.dataForm.totalScore) >= parseInt(item.score1) && parseInt(this.dataForm
						.totalScore) <= parseInt(item.score2)) {

						this.dataForm.totalResult = item.cpjg
						this.dataForm.scoreAnalysis = item.jgfx
						this.dataForm.scoreAdvice = item.tzjy
					}
				}
			},
			handleClick(val) {
				
				
					if(this.tabName=='观看视频'){
						for (let item of this.form.diagnosisRecord) {
							let diagnosisModel = item.diagnosisModel
						
							if(diagnosisModel.stimulusForm=='视频观看'){
								this.startTime = item.startTime
								this.endTime = item.endTime
							
								this.setEcharts()
								this.setEcharts2()
							}
						}
					}
					if(this.tabName=='朗读文本'){
						
						for (let item of this.form.diagnosisRecord) {
							let diagnosisModel = item.diagnosisModel
						
							if(diagnosisModel.stimulusForm=='文字朗读'){
							
								this.startTime = item.startTime
								this.endTime = item.endTime
							
								this.setEcharts()
								this.setEcharts2()
								
							}
						}
					}
				//this.getInfo(this.form.vfRelationInfo[val.index])
			},
			async getInfo(tableInfo) {
				this.dialogVisible = true
				this.$nextTick(()=>{
					let sroll = this.$refs.mainSroll
					sroll.scrollTo({top:0})
				})
				
				var that = this;
				const {
					data
				} = await this.$httpAes({
					url: that.$httpAes.adornUrl("/vfTableInfo/info"),
					method: "post",
					data: {
						stringParam1: tableInfo.infoId,
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (data.status) {
					console.log('tableInfo')
					console.log(data.data)
					this.tableName = data.data.tableName;
					if (data.data && data.data.tableContent) {
						this.formJson = JSON.parse(data.data.tableContent);
					

						console.log(this.formJson)
						this.$nextTick(() => {
								that.$refs["vFormRef"].setFormJson(this.formJson);
							that.$refs["vFormRef"].disableForm(true);
							setTimeout(()=>{
								that.$refs["vFormRef"].disableForm(true);
							},500)
							this.getWriteInfo(tableInfo);
						});
						// if (this.$route.query.activeName == 3) {
						//   this.getWriteInfo(tableInfo);
						// }


					}
				} else {
					that.$message({
						message: data.data.msg,
						type: "error",
						duration: 1500,
						onClose: () => {},
					});
				}
			},
		
			async getWriteInfo(tableInfo) {
				var that = this;
				console.log('getWriteInfo')
				// let formInfo = {};
				// if (localStorage.currentPatientFormInfo) {
				//   formInfo = JSON.parse(localStorage.currentPatientFormInfo);
				//   console.log(formInfo, "formInfo");
				// }
				const {
					data
				} = await this.$httpAes({
					url: that.$httpAes.adornUrl("/vfWriteInfo/info"),
					method: "post",
					data: {
						stringParam1: tableInfo.infoId,
						stringParam2: tableInfo.id,
						stringParam3: tableInfo.taskId,
						stringParam4: tableInfo.patientId,
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (data.status) {
					let list = [],
						newArr = [];
					if (data.data) {
						list = JSON.parse(data.data);
						// console.log('list',list)
						if (list.length) {
							list.forEach((ele) => {
								if (ele.cellId.indexOf('radio') != -1) {
									ele.fillContent = parseInt(ele.fillContent);
								}
								if (ele.cellId.indexOf('checkbox') != -1) {
									if (ele.fillContent.indexOf('[') > -1) {
										ele.fillContent = JSON.parse(ele.fillContent);
									}
								}

								if (ele.fillType == "checkbox") {
									if (ele.fillContent.indexOf('[') > -1) {
										ele.fillContent = JSON.parse(ele.fillContent);
									}
									// if (typeof ele.fillContent == 'number') {
									//   ele.fillContent = [ele.fillContent]
									// }
								}
								if (
									ele.fillType == "number" ||
									ele.fillType == "radio" ||
									ele.fillType == "slider"
								) {
									console.log(typeof ele.fillContent);
									ele.fillContent = parseInt(ele.fillContent);
									// if (typeof ele.fillContent == "string") {

									// }
								}
								if (
									ele.fillType == "picture-upload" ||
									ele.fillType == "file-upload" ||
									ele.fillType == "pictureupload"
								) {
									if (typeof ele.fillContent == "string") {
										if (ele.fillContent.indexOf("[") > -1) {
											ele.fillContent = JSON.parse(ele.fillContent);
										} else {
											ele.fillContent = [{
												url: ele.fillContent,
												name: "图片",
											}, ];
										}
									}
								}
								if (ele.fillType == "select") {
									if (ele.fillContent.indexOf("[") > -1) {
										ele.fillContent = JSON.parse(ele.fillContent);
									} else {
										ele.fillContent = parseInt(ele.fillContent);
									}
								}
								let newObj = {
									[ele.cellId]: ele.fillContent,
								};
								newArr.push(newObj);
							});
						}
						console.log("list", list);
						let formData = {};
						for (let key in [...newArr]) {
							formData = Object.assign(this.formData, [...newArr][key]);
						}
						console.log("=================================formData")
						console.log(formData)
						this.$nextTick(() => {
							this.$refs["vFormRef"].setFormData(formData);
						});
					}
				} else {
					that.$message({
						message: data.msg,
						type: "error",
						duration: 1500,
						onClose: () => {},
					});
				}
			},
			setCheck(val) {



			},
			handleSuccess2(response, file, fileList) {
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList2 = [obj]
			},
			handleSuccess(response, file, fileList) {
				console.log(response, "response");
				let obj = {
					name: file.name,
					url: response.result.filePath
				}
				this.fileList = [obj]

			},
			beforeUpload(file, fileType) {
				let fileName = file.name;
				let pos = fileName.lastIndexOf(".");
				let lastName = fileName.substring(pos, fileName.length);
				let type = lastName.toLowerCase();
				if (fileType == "1") {

					if (
						type != ".mp4"

					) {
						this.$message.error(
							"上传文件只能是.mp4 格式!"
						);
						this.fileList = [];
						return false;
					}
					return true;
				} else if (fileType == "2") {
					// let isLt2M = file.size / 1024 / 1024 < 50;
					if (type != ".txt") {
						this.$message.error("上传文件只能是.txt格式!");
						this.fileList2 = [];
						return false;
					}

					return true;
				}
			},
			back() {
				this.$router.back();
			},
			async showScore() {
				console.log('showScore')
				let itemObj = JSON.parse(sessionStorage.evaluationRecDetail)
				console.log()
				this.dataForm.totalScore = itemObj.totalScore
				this.taskId = itemObj.id
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: itemObj.packageId
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					// this.form = res.data;

					if (res.data.packageRule && res.data.packageRule.length > 0) {
						this.formList = JSON.parse(res.data.packageRule)
						console.log(this.formList)
						if (itemObj.totalResult && itemObj.totalResult.length > 0) {
							this.dataForm.totalResult = itemObj.totalResult
							this.dataForm.scoreAnalysis = itemObj.scoreAnalysis
							this.dataForm.scoreAdvice = itemObj.scoreAdvice
						} else {
							for (let item of this.formList) {
								if (this.dataForm.totalScore >= item.score1 && this.dataForm.totalScore <= item
									.score2) {

									this.dataForm.totalResult = item.cpjg
									this.dataForm.scoreAnalysis = item.jgfx
									this.dataForm.scoreAdvice = item.tzjy
								}
							}
						}

					}




				}

			},
			async init(id) {
				this.showScore()
				if (sessionStorage.evaluationRecDetail != undefined) {


					this.form = JSON.parse(sessionStorage.evaluationRecDetail)
					for (let item of this.form.diagnosisRecord) {
						let diagnosisModel = JSON.parse(item.diagnosisModel)
					
						if(diagnosisModel.stimulusForm=='视频观看'){
							this.startTime = item.startTime
							this.endTime = item.endTime
							
						}
					}
					
					if (this.form.vfRelationInfo && this.form.vfRelationInfo.length > 0) {

						//this.getInfo(this.form.vfRelationInfo[0])

					}

					console.log('this.form')
					console.log(this.form)
					for (let item of this.form.diagnosisRecord) {
						item.diagnosisModel = JSON.parse(item.diagnosisModel)
						if (item.diagnosisModel.stimulusForm == '视频观看') {
							this.videoRecord = item
						}
						if (item.diagnosisModel.stimulusForm == '文字朗读') {
							this.radioRecord = item
							//this.listPic(item.modelId)
						}
						if (item.diagnosisModel.modelName == '舌苔采集') {
							this.shetaiRecord = item
							//this.listPic(item.modelId)
						}
					}
				}

			},

			async getTypeList() {
				let params = {
					stringParam1: "病种",
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dict/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				// console.log(res,'res')
				this.typeList = res.data;
				let obj = this.typeList.find(this.findType)
				console.log('findfindfindfindfindfind')
				console.log(obj)
				this.form.diseaseTypeName = obj.name
			},
			findType(item) {
				return item.value == this.form.diseaseType
			},
			async listPic(id) {
				var that = this
				this.txtContent = ''
				let params = {
					stringParam1: id,
					intParam1: 119
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/listPic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

				let fileList2 = JSON.parse(res.data[0].picUrl)
				let request = new XMLHttpRequest();
				request.open("GET", fileList2[0].url, true);
				request.onload = function() {
					if (this.status >= 200 && this.status < 400) {
						let data = this.responseText;

						that.txtContent = data
					} else {
						console.error("获取文件内容失败");
					}
				};
				request.onerror = function() {
					console.error("网络连接异常");
				};
				request.send();
			},
			async insertPic(actionId) {

				let params = {
					stringParam1: []

				}

				if (this.checkList.includes('视频观看')) {
					let obj = {
						actionId: actionId,
						picUrl: JSON.stringify(this.fileList),
						picType: 118,
					}
					params.stringParam1.push(obj)
				}
				if (this.checkList.includes('文字朗读')) {
					let obj = {
						actionId: actionId,
						picUrl: JSON.stringify(this.fileList2),
						picType: 119,
					}
					params.stringParam1.push(obj)
				}
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/insertMultiHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				console.log(res, 'res')
				if (res.status) {
					this.$router.go(-1)
				}
			},
			async deleteHousePic(id) {
				let params = {
					stringParam1: id,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/sysPic/deleteHousePic"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});

			},

			async submit() {
				if (this.checkList.includes('视频观看') && this.fileList.length == 0) {
					this.$message.error('请上传视频文件')
					return
				}
				if (this.checkList.includes('文字朗读') && this.fileList2.length == 0) {
					this.$message.error('请上传文本文件')
					return
				}
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						let form = this.form;
						form.stimulusForm = this.checkList.join(',')

						const {
							data: res
						} = await this.$httpAes({
							url: this.$httpAes.adornUrl(
								form.id ? "/diagnosisModel/update" : "/diagnosisModel/add"
							),
							method: "post",
							contentType: 'json',
							headers: {
								'Content-Type': 'application/json',

							},
							data: this.form,
						});
						console.log(res);
						if (res.status) {
							this.$message.success(res.msg);
							if (form.id) {
								await this.deleteHousePic(res.data.id)
								await this.insertPic(res.data.id)
							} else {
								this.insertPic(res.data.id)
							}

						}
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	// .el-tabs__active-bar{
	// 	background-color: #fff !important;
	// }
	.el-radio__input.is-checked+.el-radio__label {
	    color: #0476F5 !important;
	}
	.el-tabs__item:hover {
	    color: #0476F5;
	    cursor: pointer;
	}
	.el-tabs__item.is-active {
	    color: #0476F5;
	}
	.el-dialog__header, .el-dialog__footer {
	    border: 0px;
	}
	.el-tabs__active-bar {
	    position: absolute;
	    bottom: 0;
	    left: 0;
	    height: 2px;
	    background-color: #0476F5;
	    z-index: 1;
	    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
	    list-style: none;
	}
	.recordDetail {
		display: flex;
		width: 100%;
		height: 100%;
		background: url("../../assets/img/record/bag.png") center center;
		background-size: 100% 100%;
		padding: 32px;
		.chartTitle{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: .16rem;
			color: #333333;
			line-height: .22rem;
			text-align: left;
			font-style: normal;
		}
		.zwsj{
			margin: auto;
			text-align: center;
			width: 1.47rem;
			height: 1.06rem;
		}
		.zwsj4{
			margin: auto;
			text-align: center;
			width: .9rem;
			height: .87rem;
		}
			
		.zwdf{
			margin: auto;
			text-align: center;
			width: 1.68rem;
			height: .2rem;
		}
		.echartContent{
			box-sizing: border-box;
			
			//padding:.12rem .16rem;
			//width:calc(100% - 48px);height:2.14rem;
			width: 6.68rem;
			height: 2.14rem;
			background: #F9FBFF;margin-top: .25rem;
			margin-left: .24rem;
			
		}
		.qsLine{
			display: flex;
			justify-content: space-between;
			margin-bottom: .27rem;
			margin-left: .24rem;
			margin-right: .24rem;
		}
		.qsRightContent{
			font-family: AlibabaPuHuiTiR;
			font-size: .14rem;
			color: #0566D2;
			line-height: .21rem;
			text-align: left;
			font-style: normal;
			cursor: pointer;
		}
		.qsLeftContent{
			font-family: AlibabaPuHuiTiR;
			font-size: .18rem;
			color: #333333;
			line-height: .21rem;
			text-align: left;
			font-style: normal;
		}
			.infoLine{
				display: flex;
				margin-top: .24rem;
				margin-left: .24rem;
			}
			.infoLabel{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: .14rem;
				color: #333333;
				line-height: .22rem;
				text-align: right;
				font-style: normal;
				margin-right: .16rem;
			}
			.infoContent1{
				font-family: PingFangSC, PingFang SC;
				font-weight: 600;
				font-size: .18rem;
				color: #0476F5;
				line-height: .22rem;
				text-align: left;
				font-style: normal;
			}
			.infoContent2{
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: .14rem;
				color: #333333;
				line-height: .22rem;
				text-align: left;
				font-style: normal;
			}
			.infoStatus1{
				width: .87rem;
				height: .25rem;
				background: #EAF7EA;
				border-radius: 4px;
				text-align: center;
				line-height: .25rem;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: .14rem;
				color: #3AC50E;
				font-style: normal;
			}
			.infoStatus2{
				width: .87rem;
				height: .25rem;
				background: #F8EBE9;
				border-radius: 4px;
				text-align: center;
				line-height: .25rem;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: .14rem;
				color: #F41717;
				font-style: normal;
			}
			.infoStatus3{
				width: .87rem;
				height: .25rem;
				background: #FFF2EB;
				border-radius: 4px;
				text-align: center;
				line-height: .25rem;
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: .14rem;
				color: #F2712D;
				font-style: normal;
			}
		.labelContent{
			width: 2.37rem;
			height: .31rem;
			background: linear-gradient( 270deg, rgba(255,255,255,0) 0%, #89A6FF 100%);
			border-radius: .16rem 0px 0px .16rem;
			
		}
		.labelBtn{
			width: 1.28rem;
			height: .31rem;
			line-height: .31rem;
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			font-size: .18rem;
			color: #FFFFFF;
			text-align: center;
			font-style: normal;
			background: linear-gradient( 270deg, #60D7F2 0%, #718AF8 100%);
			border-radius: .16rem;
		}
		.leftContent{
			// width: 400px;
			// height: 866px;
			// margin-right: .24rem;
			width: 4rem;
			height: 8.66rem;
			margin-right: .24rem;
			background: #FFFFFF;
			border-radius: 4px;
			
		}
		.midContent{
			// height: 866px;
			// margin-right: .24rem;
			// width: 7.16rem;
			width: 7.16rem;
			height: 8.66rem;
			margin-right: .24rem;
			background: #FFFFFF;
			border-radius: 4px;
		}
		.rightContent{
			//height: 866px;
			display: flex;
			flex-wrap: wrap;
			//width: 692px;
			width: 6.92rem;
			height: 8.66rem;
		}
		.rightContent1{
			// width: 692px;
			// height: 351px;
		width: 6.92rem;
		height: 3.51rem;
			background: url("../../assets/img/record/img2.png") center center;
			background-size: 100% 100%;
			margin-bottom: .25rem;
		}
		.rightContent2{
			// width: 692px;
			// height: 490px;
			width: 6.92rem;
			height: 4.9rem;
			background: #FFFFFF;
			border-radius: 4px;
		}
		.aud {
			width: 278px;
			height: 51px;
			margin-top: 30px;
			margin-left: calc(50% - 139px);
		}

		.form-title {
			font-size: .2rem;
			color: #0476F5;
			font-weight: bold;
			width: 100%;
			text-align: center;
			padding: 10px 0 40px;
		}

		.label2 {
			height: 26px;
			font-size: .16rem;
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 26px;
		}

		.scoreContent {
			font-size: .14rem;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: .22rem;
			width: 100%;
			height: 54px;
			background: #F8F3FF;
			box-sizing: border-box;
			padding: .16rem;
			margin-bottom: .24rem;
		}

		.ldwb {
			white-space: pre-wrap;
			font-size: .14rem;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: .22rem;
			text-indent: 2ch;
		}

		.top2 {
			display: flex;

			// height: 200px;
			.left {
				height: 100%;
				width: 100%;

				//   background-color: aqua;
				ul {
					li {
						display: flex;
						padding: .12rem 0px;

						.label {
							width: 140px;
							height: .22rem;
							font-size: .14rem;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: .22rem;
							text-align: right;
						}

						.content {
							width: calc(100% - 156px);
							margin-left: .16rem;
						}

						span {
							margin-left: .16rem;
							height: .22rem;
							font-size: .14rem;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: .22rem;
						}
					}
				}
			}


		}

		.top {
			display: flex;

			// height: 200px;
			.left {
				height: 100%;
				width: 50%;

				//   background-color: aqua;
				ul {
					li {
						display: flex;
						padding: .12rem 0px;

						.label {
							width: 140px;
							height: .22rem;
							font-size: .14rem;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: .22rem;
							text-align: right;
						}

						span {
							margin-left: .16rem;
							height: .22rem;
							font-size: .14rem;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: .22rem;
						}
					}
				}
			}

			.right {
				height: 100%;
				flex: 1;

				//   background-color: antiquewhite;
				ul {
					li {
						display: flex;
						padding: .12rem 0px;

						.label {
							width: 140px;
							height: .22rem;
							font-size: .14rem;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: .22rem;
							text-align: right;
						}

						span {
							margin-left: .16rem;
							height: .22rem;
							font-size: .14rem;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: .22rem;
						}
					}
				}
			}
		}
	}
</style>